<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { useWallet } from '~/src/composables/apis/wallet'
import { useWalletsModal } from '~/src/composables/core/modals'
import { CurrencyCode } from '~/src/types/internals'

const { globalState, requestUsdAccount } = useWallet()

const handleClick = () => {
  useWalletsModal().closeCreateUSDAccount()
}

const requestAccount = () => {
  requestUsdAccount(CurrencyCode.USD)
}
</script>
<template>
  <Modal
    modal="$atts.modal"
    full-page-view
  >
    <main class="bg-white relative">
      <TopBar>
        <template #title>
          <IconBtn
            label="close"
            class="flex-2"
            type="button"
            @click="handleClick"
          >
            <MaIcon
              name="add"
              class="w-6 !text-secondary rotate-45"
            />
          </IconBtn>
          <Typography
            as="h1"
            class="flex-1 hidden md:block"
            variant="xlBold"
          >
            {{ $t('wallets.createUsdAccount') }}
          </Typography>
          <Typography
            as="h1"
            class="flex-1 md:hidden"
            variant="xsMedium"
          >
            {{ $t('wallets.createUsdAccount') }}
          </Typography>
        </template>
      </TopBar>

      <div class="relative mt-24 md:mx-8 lg:mx-16 flex justify-center items-center">
        <LayoutContent
          :spacing="17"
          class="flex-1 pt-16 md:pt-0"
        >
          <div class="max-w-xl md:max-w-2xl lg:max-w-[540px] md:pl-48 lg:pl-12 xl:pl-0 px-5 md:px-8 mx-auto">
            <div class="mb-12">
              <MaIcon
                name="identity-verify-illustration"
                class="mx-auto h-[192px]"
              />
            </div>

            <Typography
              variant="xxlBold"
              color="text-gray900"
            >
              {{ $t('wallets.theMapleradUsdAccount') }}
            </Typography>

            <ul class="flex flex-col gap-6 mt-6">
              <li class="flex gap-4">
                <div class="h-6 mt-1 w-6">
                  <MaIcon
                    name="world-two-tone"
                    class="h-6 w-6"
                  />
                </div>

                <div class="">
                  <Typography
                    variant="lg"
                    class="font-medium"
                    color="text-gray900"
                    >{{ $t('wallets.receiveInternationalPayments') }}
                  </Typography>

                  <Typography
                    variant="sm"
                    color="text-gray500"
                    >{{ $t('wallets.getPaidByForeignClients') }}
                  </Typography>
                </div>
              </li>

              <li class="flex gap-4">
                <div class="h-6 mt-1 w-6">
                  <MaIcon
                    name="zap-two-tone"
                    class="h-7 w-4"
                  />
                </div>

                <div class="">
                  <Typography
                    variant="lg"
                    class="font-medium"
                    color="text-gray900"
                    >{{ $t('wallets.convenientAndQuick') }}
                  </Typography>

                  <Typography
                    variant="sm"
                    color="text-gray500"
                    >{{ $t('wallets.experienceTheEase') }}
                  </Typography>
                </div>
              </li>

              <li class="flex gap-4">
                <div class="h-6 mt-1 w-6">
                  <MaIcon
                    name="dollar-coin-two-tone"
                    class="h-6 w-6"
                  />
                </div>

                <div class="">
                  <Typography
                    variant="lg"
                    class="font-medium"
                    color="text-gray900"
                    >{{ $t('wallets.reducedFees') }}
                  </Typography>

                  <Typography
                    variant="sm"
                    color="text-gray500"
                    >{{ $t('wallets.achWireTransfersSupported') }}
                  </Typography>

                  <ul class="list-disc pl-4">
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.depositFees') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.monthlyMaintenanceFees') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.accountCreationFee') }}
                    </Typography>
                  </ul>
                </div>
              </li>

              <li class="flex gap-4">
                <div class="h-6 mt-1 w-6">
                  <MaIcon
                    name="steps"
                    class="h-6 w-6"
                  />
                </div>

                <div>
                  <Typography
                    variant="lg"
                    class="font-medium"
                    color="text-gray900"
                    >{{ $t('wallets.paymentsWeSupport') }}
                  </Typography>

                  <ul class="list-disc pl-4">
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.firstParty') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.payroll') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.business') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.family') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.internationalAchTransactions') }}
                    </Typography>
                    <Typography
                      variant="sm"
                      as="li"
                      color="text-gray500"
                      >{{ $t('wallets.wireTransfers') }}
                    </Typography>
                  </ul>
                </div>
              </li>
            </ul>

            <Typography
              variant="sm"
              color="text-gray500"
              class="mt-12 mb-4"
            >
              {{ $t('wallets.thisApplicationUsesBridge') }}

              <Typography
                variant="sm"
                as="NuxtLink"
                color="text-primary"
                target="_blank"
                to="https://www.bridge.xyz/legal"
              >
                {{ $t('wallets.bridgesTermsOfService') }}
              </Typography>
            </Typography>
            <Button
              class="w-full"
              size="large"
              :loading="globalState.loading.requestingUSDAccount"
              @click="requestAccount"
              >{{ $t('wallets.createUsdAccount') }}
            </Button>
          </div>
        </LayoutContent>
      </div>
    </main>
  </Modal>
</template>
