import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { GATEWAY_ENDPOINT, UN_AUTH_GATEWAY_ENDPOINT, AUTH_GATEWAY_ENDPOINT } from '../../SetupBase'
import { CreateAccount, RequestAccount, RequestAccountResponse, VirtualAccount } from '../wallet/types'
import {
  BusinessAddress,
  BusinessDetails,
  Director,
  SelectBusinessRequest,
  Shareholder,
  ToggleAccount,
} from '../kyc/types'
import { BusinessSettings, GenerateKeyResponse, NewBusiness } from './type'
import { ServerResponse, Document, Ownership, AccountStatusResponse, DocFile } from '~/src/types/api'
import { CurrencyCode } from '~/src/types/internals'
import { Business } from '~/src/composables/apis/business/types'
import { CamelizeKeys, Snakify, SnakifyKeys } from '~/src/types/utilities'

export const businessApi = {
  $_requestBusinessVirtualAccount: (
    payload: RequestAccount,
  ): Promise<AxiosResponse<RequestAccountResponse>> | 'ERROR' => {
    const url = '/businesses/virtual-account/request'
    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload), {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_createBusinessVirtualAccount: (
    credential: SnakifyKeys<CreateAccount>,
  ): Promise<AxiosResponse<ServerResponse<undefined>>> | 'ERROR' => {
    // TODO: endpoint has been deprecated
    const url = '/businesses/virtual-account/request'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_createBusiness: (credential: NewBusiness): Promise<AxiosResponse<ServerResponse<Business>>> | 'ERROR' => {
    const url = '/businesses'
    return AUTH_GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(credential))
  },
  $_getVirtualAccountById: (accountId: string): Promise<AxiosResponse<ServerResponse<VirtualAccount>>> => {
    const url = `collections/virtual-account/${accountId}`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getBusinessVirtualAccount: (
    currency: CurrencyCode,
  ): Promise<AxiosResponse<ServerResponse<AccountStatusResponse>>> => {
    const url = `/businesses/virtual-account/${currency}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_getBusinessVirtualAccountStatus: () => {
    const url = `/businesses/virtual-account/status`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_createWebhook: (credential: {
    webhook_url: string
    test: boolean
  }): Promise<AxiosResponse<undefined>> | 'ERROR' => {
    const url = '/businesses/webhook'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_deleteWebhook: (test: boolean): Promise<AxiosResponse<undefined>> | 'ERROR' => {
    const url = '/businesses/webhook'
    return GATEWAY_ENDPOINT.delete(url, { data: { test } })
  },
  $_getBusinessSettings: (): Promise<AxiosResponse<BusinessSettings>> | 'ERROR' => {
    const url = '/businesses/settings'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getKycLevelOne: async (): Promise<ServerResponse<SnakifyKeys<BusinessAddress & BusinessDetails>>> => {
    const url = '/businesses/kyc-level-one'
    const res = await GATEWAY_ENDPOINT.get(url)

    return res?.data
  },
  $_getKycLevelTwo: async (): Promise<ServerResponse<SnakifyKeys<Director>[]>> => {
    const url = '/businesses/kyc-level-two'
    const res = await GATEWAY_ENDPOINT.get(url)

    return res?.data
  },
  $_sendDirectorEmail: (id: string) => {
    const url = `businesses/kyc-level-two/${id}/send-mail`

    return GATEWAY_ENDPOINT.get(url)
  },
  $_kycOne: (
    credential: SnakifyKeys<BusinessAddress & BusinessDetails>,
  ): Promise<AxiosResponse<ServerResponse<undefined>>> => {
    const url = '/businesses/kyc-level-one'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_deleteKycDoc: (id: string) => {
    const url = '/businesses/kyc-documents'
    return GATEWAY_ENDPOINT.delete(`${url}/${id}`)
  },
  $_kycUploadDoc: (credential: Record<Snakify<string>, DocFile[] | string>) => {
    const url = '/businesses/kyc-documents'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_getKycDoc: async (): Promise<ServerResponse<SnakifyKeys<Document>[]>> => {
    const url = '/businesses/kyc-documents'
    const res = await GATEWAY_ENDPOINT.get(url)

    return res?.data
  },
  $_completeDirector: (credential: SnakifyKeys<Director>): Promise<AxiosResponse<ServerResponse<undefined>>> => {
    const url = '/businesses/kyc-level-two'
    return UN_AUTH_GATEWAY_ENDPOINT.patch(url, credential) // TODO: merge AUTH_GATEWAY_ENDPOINT and UN_AUTH_GATEWAY_ENDPOINT
  },
  $_getDirectorById: (
    id: string,
  ): Promise<AxiosResponse<ServerResponse<{ associate: Director; business: CamelizeKeys<Business> }>>> => {
    const url = `businesses/kyc-level-two/${id}`
    return UN_AUTH_GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_getAssociateById: (id: string): Promise<AxiosResponse<ServerResponse<Director>>> => {
    const url = `/businesses/associates/${id}`
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_editKycDirector: (credential: SnakifyKeys<Director>) => {
    const { id, ...rest } = credential
    const url = '/businesses/associates'
    return GATEWAY_ENDPOINT.put(`${url}/${id}`, rest)
  },
  $_deleteKycDirector: (id: string) => {
    const url = '/businesses/associates'
    return GATEWAY_ENDPOINT.delete(`${url}/${id}`)
  },
  $_deleteKycShareholder: (id: string) => {
    const url = '/shareholder'
    return GATEWAY_ENDPOINT.delete(`${url}/${id}`)
  },
  $_getKycShareholder: (): Promise<ServerResponse<{ data: SnakifyKeys<Ownership> }>> => {
    const url = '/shareholder/'
    return GATEWAY_ENDPOINT.get(url)
  },
  $_kycEditShareholder: (credential: SnakifyKeys<Shareholder>): Promise<ServerResponse<SnakifyKeys<Shareholder>[]>> => {
    const url = '/shareholder/'
    return GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_kycShareholder: (credential: SnakifyKeys<Shareholder>): Promise<ServerResponse<SnakifyKeys<Shareholder>[]>> => {
    const url = '/shareholder/'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_kycAddDirector: (credential: SnakifyKeys<Director>): Promise<AxiosResponse<ServerResponse<undefined>>> => {
    const url = '/businesses/kyc-level-two'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_selectBusiness: (payload: SelectBusinessRequest): Promise<AxiosResponse<ServerResponse<undefined>>> | 'ERROR' => {
    const url = `/businesses/switch`
    return GATEWAY_ENDPOINT.patch(url, Recase.snakeCopy(payload))
  },
  $_toogleTestAccount: (payload: ToggleAccount): Promise<AxiosResponse<ServerResponse<undefined>>> | 'ERROR' => {
    const url = `/config/switch-environment`
    return GATEWAY_ENDPOINT.patch(url, Recase.snakeCopy(payload))
  },
  $_getMyBussiness: async (params?: { page?: number; page_size?: number }) => {
    const query = new URLSearchParams('')

    query.set('page', String(params?.page || 1))
    query.set('page_size', String(params?.page_size || 10))

    const url = '/businesses/me'
    const res = await AUTH_GATEWAY_ENDPOINT.get(`${url}?${query.toString()}`)

    return res?.data
  },
  $_getBusinessActivities: (credential: any) => {
    const url = `/statistics/api-calls?month=${credential.month}&year=${credential.year}`
    return GATEWAY_ENDPOINT.get(url)
  },
  $_generateKeys: (test: boolean): Promise<AxiosResponse<GenerateKeyResponse>> | 'ERROR' => {
    const url = `/businesses/generate-keys`
    return GATEWAY_ENDPOINT.post(
      url,
      {
        test,
      },
      {
        transformResponse: (data: string) => Recase.camelCopy(JSON.parse(data)),
      },
    )
  },
  $_updateBusiness: (credential: Partial<Business>) => {
    const url = '/businesses'
    return GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_submitKYC: () => {
    const url = '/businesses/submit-kyc'
    return GATEWAY_ENDPOINT.put(url)
  },
}
